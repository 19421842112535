import { qspost } from '@/config/axios';

//获取精选数据
export const getList = data => qspost("request?", data, "videosBest");

//获取视频列表
export const getVideoArr = data => qspost("request?", data, "getVideoTitle");

//获取视频分类
export const getType = data => qspost("request?",data,"videosType");

//新增精选
export const add = data => qspost("request?", data, "addVideosBest");

//编辑精选
export const edit = data => qspost("request?", data, "updateVideosBest");

//删除精选
export const del = data => qspost("request?", data, "deleteVideosBest");

//冻结*解冻
export const freezeThaw = data => qspost("request?", data, "lockVideosBest");

//获取精选已有视频数据
export const getVideo = data => qspost("request?", data, "getBestVideo");

//修改精选数据
export const editVideo = data => qspost("request?", data, "addBestVideo");

//获取精选下的视频数据
export const getVideoBest = data => qspost("request?", data, "getVideoBest");

//移除精选下的视频数据
export const removeBestVideo = data => qspost("request?", data, "removeBestVideo");