<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <span class="search-box-title">分类：</span>
        <el-select v-model="searchArr.type_id" filterable placeholder="全部" size="medium" clearable>
          <el-option :key="-1" label="全部" :value="-1"></el-option>
          <el-option :key="0" label="未分类" :value="0"></el-option>
          <el-option v-for="item in typeArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <span class="search-box-title">状态：</span>
        <el-select v-model="searchArr.status" filterable placeholder="全部" size="medium" clearable>
          <el-option :key="-1" label="全部" :value="-1"></el-option>
          <el-option :key="0" label="禁用" :value="0"></el-option>
          <el-option :key="1" label="启用" :value="1"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="dialogAdd = true;title = '添加精选'">添加精选</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{ background: '#F7F8FA' }">
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="title" label="推荐精选" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="type" label="分类" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="weight" label="权重" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="counter" label="总作品数" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div @click="getVideoBest(scope.row)" class="mouse">{{scope.row.counter}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="enable" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div :style="{color: scope.row.enable == '启用' ? '#33C933' : '#F33333' }">{{scope.row.enable}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="order_type" label="排序" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <span>{{scope.row.order_type==0?"固定":"随机"}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300px" align="center">
        <template #default="scope">
          <span class="operation" @click="changeOrderType(scope.row)">{{scope.row.order_type==0?"随机排序":"固定排序"}}</span>
          <span class="operation" @click="editor(scope.row)">编辑</span>
          <span class="operation" @click="getVideo(scope.row)">添加视频</span>
          <span class="operation" @click="freezeThaw(scope.row,scope.row.enable == '启用' ? '禁用' : '启用')">{{scope.row.enable == '启用' ? '禁用' : '启用'}}</span>
          <span class="operation" @click="del(scope.row)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />
    <!-- 添加修改精选弹窗 -->
    <el-dialog :title="title" v-model="dialogAdd" width="492px" top="10vh" :close-on-click-modal="false" @close="closeDialog">
      <div class="form_box">
        <div class="form_title">精选名称：</div>
        <el-input v-model="dialogArr.title" placeholder="精选名称" size="small"></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">精选分类：</div>
        <el-select v-model="dialogArr.type_id" multiple filterable placeholder="无分类" size="medium">
          <el-option label="未分类" :value="0"></el-option>
          <el-option v-for="item in typeArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="form_box">
        <div class="form_title">权重：</div>
        <el-input v-model="dialogArr.weight" placeholder="权重" size="small" type="number"></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">精选介绍：</div>
        <el-input v-model="dialogArr.description" placeholder="不得超过100字" size="small" type="textarea" maxlength="100" show-word-limit :rows="7" resize="none">
        </el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="add" v-if="title == '添加精选'">确认添加</el-button>
          <el-button type="primary" @click="edit" v-else>确认修改</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 精选添加视频 -->
    <el-dialog :title="videoTitle" v-model="dialogVideo" width="1180px" top="10vh" :close-on-click-modal="false" @close="closeDialog">
      <div class="search">
        <div class="search-box">
          <span class="search-box-title">分类：</span>
          <el-select v-model="search_best.type_id" filterable placeholder="全部" size="medium" clearable>
            <el-option label="全部" :value=-1 ></el-option>
            <el-option label="未分类" :value=0 ></el-option>
            <el-option v-for="item in typeArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="search-box">
          <el-select v-model="search_best.checked" placeholder="全部" size="medium" >
            <el-option label="全部" value="-1"></el-option>
            <el-option label="未添加" value="0"></el-option>
            <el-option label="已添加" value="1"></el-option>
          </el-select>
        </div>
          <div class="search-box">
            <el-input v-model="search_best.title" placeholder="输入视频标题搜索" size="small" />
          </div>
          <div class="search-box">
            <el-button @click="pager_best_video_list.page = 1,getBestVideoList()">查询</el-button>
          </div>
      </div>
      <div style="padding-bottom:60px">
        <div >
          <el-table :data="bestVideoListTable" :header-cell-style="{ background: '#F7F8FA' }" height="538">
            <el-table-column prop="id" label="ID" width="100px" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="title" label="标题" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="type" label="分类" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column width="200px" align="center">
              <template #default="scope">
                <span class="operation" v-if="!scope.row.checked" @click="addBestVideo(scope.row)">添加</span>
                <span class="operation" v-else style="color:#ccc; text-decoration: underline" @click="cancelBestVideo(scope.row)">取消</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <page :pager="pager_best_video_list" @query="getBestVideoList()" />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="dialogVideo=false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 精选已有视频显示表格 -->
    <el-dialog :title="videoArrTitle" v-model="videoArrDialog" width="1000px" top="10vh" :close-on-click-modal="false" @close="closeDialog">
      <div style="padding-bottom:60px">
        <el-table :data="videoData.filter(data => !search || data.title.toLowerCase().includes(search.toLowerCase()))"
          :header-cell-style="{ background: '#F7F8FA' }">
          <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="title" label="标题" align="center" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="type" label="所属分类" align="center" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="watch_count" label="观看次数" align="center" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="good_count" label="点赞次数" align="center" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column width="200px" align="center">
            <template #header>
              <el-input v-model="search" placeholder="输入视频标题搜索" size="small" />
            </template>
            <template #default="scope">
              <span class="operation" @click="removeBestVideo(scope.row)">移除</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getList,
  add,
  edit,
  del,
  freezeThaw,
  // getVideo,
  getVideoArr,
  editVideo,
  getVideoBest,
  removeBestVideo,
  getType,
} from "@/api/video/recommend";
import { nextTick } from "vue";
import page from "@/components/page";
import httpClient from "@/config/httpClient";
export default {
  name: "videoRecommend",
  components: {
    page,
  },
  data() {
    return {
      pager: { total: 0, page: 1, rows: 10 },
      pages: { total: 0, page: 1, rows: 10 },
      pager_best_video_list: { total: 0, page: 1, rows: 10 },
      searchArr: {
        //搜索
        type_id: -1, //分类
        status: -1, //状态
      },
      tableData: [], //数据
      row: [], //选中的数据
      title: "", //弹窗标题
      dialogAdd: false,
      dialogArr: {
        title: "", //标题
        type_id: [0],//未分类
        weight: 0, //权重
        description: "", //简介
      },
      videoTitle: "", //添加视频弹窗标题
      dialogVideo: false, //添加视频弹窗
      videoId: [], //选中的视频id
      checked: 1, //
      videoArr: [], //视频列表
      videoArrDialog: false, //已有视频弹出层
      videoArrTitle: "", //已有视频弹出层标题
      search: "",
      videoData: [], //已有视频表格数据
      recommend: [], //精选数据
      searchVideo: "", // 搜索视频
      typeArr: [], // 分类数组
      type: "", // 已选分类
      bestVideoListTable:[],
      search_best:{
        checked:"-1",
        type_id:-1,
        title:""
      }
    };
  },
  mounted() {
    this.getList();
    this.getType();
  },
  methods: {
    addBestVideo(row){
      httpClient("addBestVideo").post({id:this.row.id,video_id:row.id}).then((res)=>{
        if(res.code == 0){
          row.checked = true
        }else{
          this.$message({ message: res.msg, type: "error" });
        }
      })
    },
    cancelBestVideo(row){
      httpClient("cancelBestVideo").post({id:this.row.id,video_id:row.id}).then((res)=>{
        if(res.code == 0){
          row.checked = false
        }else{
          this.$message({ message: res.msg, type: "error" });
        }
      })
    },
    getBestVideoList(){
      httpClient("getBestVideoList").post({
        id:this.row.id,
        pageid:this.pager_best_video_list.page,
        pcount:this.pager_best_video_list.rows,
        checked:this.search_best.checked,
        title:this.search_best.title,
        type_id:this.search_best.type_id,
      }).then((res)=>{
        if(res.code == 0){
          this.bestVideoListTable = res.data.list
          if (this.pager_best_video_list.page == 1){
            this.pager_best_video_list.total = res.data.total
          }
        }else{
          this.$message({ message: res.msg, type: "error" });
        }
      })
    },
    //获取精选数据
    getList() {
      if (!this.searchArr.type_id && this.searchArr.type_id !== 0) {
        this.searchArr.type_id = -1;
      }
      if (!this.searchArr.status && this.searchArr.status !== 0) {
        this.searchArr.status = -1;
      }
      getList([
        { key: "page", val: this.pager.page },
        { key: "row", val: this.pager.rows },
        { key: "type_id", val: this.searchArr.type_id },
        { key: "status", val: this.searchArr.status },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.pager.total = res.data.total;
            this.tableData = res.data.list;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取视频分类
    getType() {
      getType().then((res) => {
        if (res.code == 0) {
          this.typeArr = res.data;
        }
      });
    },
    //获取视频
    getVideoArr() {
      getVideoArr().then((res) => {
        if (res.code == 0) {
          this.videoArr = res.data;
          this.pages.total = res.data.length;
        } else {
          this.$message({ message: res.msg, type: "error" });
        }
      });
    },
    //新增精选
    add() {
      if (this.dialogArr.weight < 0)
        return this.$message({ message: "权重不能未负数", type: "error" });
      add([
        { key: "title", val: this.dialogArr.title },
        { key: "type_id", val: this.dialogArr.type_id.join(",") || 0 },
        { key: "weight", val: this.dialogArr.weight },
        { key: "description", val: this.dialogArr.description },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.getList();
            this.$message({ message: res.data, type: "success" });
            this.dialogAdd = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeOrderType(row){
      let order_type = row.order_type ==0?1:0
      httpClient("VideoBestChangeOrderType").post({id:row.id,order_type:order_type}).then((res)=>{
        if(res.code != 0){
          this.$message({ message: res.msg, type: "error" });
        }else{
          row.order_type = order_type
        }
      })
    },
    //把数据放入编辑内
    editor(row) {
      let typeId = row.type_id.split(",");
      let arr = [];
      for (let i = 0; i < typeId.length; i++) {
        arr.push(Number(typeId[i]));
      }
      this.row = row;
      this.title = `编辑精选(ID：${row.id})`;
      this.dialogArr = {
        title: row.title, //标题
        type_id: arr,// 分类
        weight: row.weight, //权重
        description: row.description, //简介
      };
      this.dialogAdd = true;
    },
    //编辑精选
    edit() {
      if (this.dialogArr.weight < 0)
        return this.$message({ message: "权重不能未负数", type: "error" });
      edit([
        { key: "id", val: this.row.id },
        { key: "title", val: this.dialogArr.title },
        { key: "type_id", val: this.dialogArr.type_id.join(",") || 0 },
        { key: "weight", val: this.dialogArr.weight },
        { key: "description", val: this.dialogArr.description },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.getList();
            this.$message({ message: res.data, type: "success" });
            this.dialogAdd = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //删除精选
    del(row) {
      this.row = row;
      this.$confirm(
        "你确定要删除精选：" + row.title + "吗？此操作无法撤回,请谨慎操作",
        "提示",
        { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }
      )
        .then(() => {
          del([{ key: "id", val: this.row.id }])
            .then((res) => {
              if (res.code == 0) {
                this.getList();
                this.$message({ message: res.data, type: "success" });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //冻结*解冻
    freezeThaw(row, str) {
      this.row = row;
      this.$confirm(
        "你确定要" + str + "视频精选：" + row.title + "吗？",
        "提示",
        { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }
      )
        .then(() => {
          freezeThaw([{ key: "id", val: this.row.id }])
            .then((res) => {
              if (res.code == 0) {
                this.getList();
                this.$message({ message: res.data, type: "success" });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取精选已有视频数据
    getVideo(row) {
      // if (!this.checked)
      //   return this.$message({
      //     message: "整理数据中，请等待10s后再试",
      //     type: "warning",
      //   });
      this.row = row;
      this.dialogVideo = true;
      this.videoTitle = "添加视频到精选 >>> " + row.title;
      this.getBestVideoList()
      //
      // getVideo([{ key: "id", val: this.row.id }])
      //   .then((res) => {
      //     if (res.code == 0) {
      //       this.checked = false;
      //       for (let i = 0; i < res.data.length; i++) {
      //         let is = this.videoArr.indexOf(
      //           this.videoArr.filter((data) => data.id == res.data[i])[0]
      //         );
      //         if (is != -1) {
      //           this.videoId.push(
      //             this.videoArr.filter((data) => data.id == res.data[i])[0]
      //           );
      //           this.videoArr[
      //             this.videoArr.indexOf(
      //               this.videoArr.filter((data) => data.id == res.data[i])[0]
      //             )
      //           ].checked = true;
      //         }
      //       }
      //     } else {
      //       this.$message({ message: res.msg, type: "error" });
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    //添加视频到已有视频列表
    addVideoList(data) {
      let index = this.videoArr.indexOf(data);
      this.videoId.push(data);
      this.videoArr[index].checked = true;
    },
    //移除视频出已有视频列表
    delVideoList(data) {
      let index = this.videoId.indexOf(data);
      nextTick("checked", this.videoId[index]);
      let obj = this.videoId[index];
      this.videoId.splice(index, 1);
      this.videoArr[this.videoArr.indexOf(obj)].checked = false;
    },
    // 清空
    emptyVideoList() {
      for (let i = 0; i < this.videoId.length; ) {
        nextTick("checked", this.videoId[0]);
        let obj = this.videoId[0];
        this.videoId.splice(0, 1);
        this.videoArr[this.videoArr.indexOf(obj)].checked = false;
      }
      this.checked = true;
    },
    // 添加视频下拉框改变时
    changeType() {
      console.log(this.type);
    },
    //更新精选视频数据
    editVideo() {
      let arr = [];
      this.videoId.forEach((index) => {
        arr.push(index.id);
      });
      editVideo([
        { key: "id", val: this.row.id },
        { key: "videoId", val: arr.join(",") || 0 },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.getList();
            this.$message({ message: res.msg, type: "success" });
            this.dialogVideo = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //关闭弹窗清空数据
    closeDialog() {
      this.dialogArr = {
        title: "", //标题
        type_id:[0],// 分类
        weight: 0, //权重
        description: "", //简介
      };
      this.row = []; //清空选中数据
      this.recommend = []; //清空选中的精选
      // 清空全部checked
      this.emptyVideoList();
      this.pages.page = 1;
    },
    //获取精选下所有视频数据并且打开弹窗
    getVideoBest(row) {
      this.recommend = row;
      this.$message({ message: "获取视频数据中~" });
      getVideoBest([{ key: "id", val: row.id }])
        .then((res) => {
          if (res.code == 0) {
            this.videoArrTitle = `视频列表(精选：${row.title})`;
            this.videoData = res.data;
            this.videoArrDialog = true;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //删除精选下某个视频数据
    removeBestVideo(row) {
      this.$confirm("你确定要移除视频：" + row.title + "吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeBestVideo([
            { key: "id", val: this.recommend.id },
            { key: "video_id", val: row.id },
          ])
            .then((res) => {
              if (res.code == 0) {
                this.getVideoBest();
                this.$message({ message: res.data, type: "success" });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSizeChange(val) {
      this.pages.rows = val;
    },
    handleCurrentChange(val) {
      this.pages.page = val;
    },
  },
};
</script>

<style scoped>
.form_box {
  overflow: hidden;
  margin-bottom: 18px;
}
.form_title {
  width: 30%;
  float: left;
  height: 32px;
  line-height: 32px;
}
.form_box >>> .el-input,
.form_box >>> .el-textarea {
  width: 60%;
}
.form_box >>> .el-select .el-input {
  width: 100%;
}
/* 图片上传部分 */
.form_box >>> .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.form_box >>> .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.form_box >>> .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.form_box >>> .avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.form_box >>> .note {
  margin-left: 30%;
}
.mouse {
  cursor: pointer;
}
.pages {
  height: 30px;
  line-height: 30px;
  margin: 10px 0;
  float: right;
}
.selecteds {
  width: 400px;
  height: 536px;
  float: right;
  border: 1px solid #ccc;
}
.selectedsHerder {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  background-color: #eee;
  padding: 0 20px;
}
.selectedsEmpty {
  float: right;
  color: #409eff;
  cursor: pointer;
}
</style>